import Vue from "vue";
import App from "./app.vue";
import store from "@/store/index";
import { i18n } from "@/plugins/i18n";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    "size-sm": 768,
    "size-md": 1024,
    "size-lg": 1256,
    "size-xl": 1344,
    "size-xxl": Infinity,
  },
});

Vue.config.productionTip = false;
new Vue({ i18n, store, render: (h) => h(App) }).$mount("#auth");
