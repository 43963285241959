<template>
  <Modal class="confirm__wrapper" @close="close()">
    <div class="confirm">
      <div class="confirm__header">
        <div class="confirm__header__icon-back" @click="back()">
          <Icon icon="back" color="#9999A9" />
        </div>

        <div
          class="confirm__header__label font-h1"
          v-text="$t('auth.confirm.title')"
        ></div>

        <div class="confirm__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>

      <div class="confirm__body">
        <div class="confirm__body__text" v-html="bodyText"></div>

        <div
          class="confirm__body__change-number font-h2"
          v-text="$t('auth.confirm.change')"
          @click="$emit('showInitial')"
        ></div>

        <Input
          v-show="!isEmailSent"
          v-bind="smsCode"
          v-model="smsCode.value"
          :error="smsCode.error"
        />

        <Button
          class="confirm__body__btn"
          :text="buttonText"
          :color="'gray'"
          :disabled="disabled"
          @click="sendCode()"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Input from "@/components/ui-kit/input/index.vue";
import Button from "@/components/ui-kit/button/index.vue";
import { axiosAuth } from "../../../services/scopes/axios-auth";
import { ytEvents } from "@/plugins/analytics";

export default {
  components: {
    Icon,
    Modal,
    Input,
    Button,
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      smsCode: {
        value: "",
        placeholder: this.$t("auth.confirm.code"),
        error: false,
        autocomplete: "one-time-code",
      },
      timerCount: 45,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
    "smsCode.value"(val) {
      if (val.length === 6) {
        ytEvents.track("Sign-in popup", "Send Auth sms code");

        this.validateCode();
      }
    },
  },
  computed: {
    bodyText() {
      return `${this.$t("auth.confirm.codeSentTo")} <b>${this.phone}</b>.`;
    },
    buttonText() {
      return this.timerCount > 0
        ? this.$t("auth.confirm.resendAfter", { seconds: this.timerCount })
        : this.$t("auth.confirm.resend");
    },
    disabled() {
      return this.timerCount > 0;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    back() {
      if (this.validate) {
        this.$emit("showSignup", { phone: this.phone });
      } else {
        this.$emit("showInitial");
      }
    },
    async sendCode() {
      if (!this.timerCount) {
        ytEvents.track("Sign-in popup", "Click Repeat sms button");

        this.$emit("loading", true);
        this.smsCode.error = false;

        let res;
        if (this.validate) {
          res = await axiosAuth
            .sendValidationCode(this.user.phone)
            .catch((e) => {
              console.log(e);
              if (e.response) {
                this.errors.password = e.response.data.data.message;
              }
            });
        } else {
          res = await axiosAuth.sendAuthCode(this.phone).catch((e) => {
            if (e.response) {
              this.smsCode.error = e.response.data.data.message;
            }
          });
        }

        if (res?.data?.success) {
          this.timerCount = 45;
        }

        this.$emit("loading", false);
      }
    },
    async validateCode() {
      this.$emit("loading", true);
      this.smsCode.error = false;

      let res;
      if (this.validate) {
        res = await axiosAuth
          .validateCode({
            phone: this.phone,
            code: this.smsCode.value,
          })
          .catch((e) => {
            ytEvents.track("Sign-in popup", "Get Failed sms auth");
            if (e.response) {
              this.smsCode.error = e.response.data.data.message;
            }
          });
      } else {
        res = await axiosAuth
          .signinByPhone({
            phone: this.phone,
            code: this.smsCode.value,
          })
          .catch((e) => {
            ytEvents.track("Sign-in popup", "Get Failed sms auth");
            if (e.response) {
              this.smsCode.error = e.response.data.data.message;
            }
          });
      }

      if (res?.data?.success) {
        ytEvents.track("Sign-in popup", "Get Success sms auth");
        this.$emit("success");

        const user = JSON.parse(res.data.data.user.replace(/'/g, '"'));
        const authUserEvent = new CustomEvent("Auth::user", {
          detail: { ...user },
        });
        fakeWindow.dispatchEvent(authUserEvent);
      }

      this.$emit("loading", false);
    },
  },
};
</script>

<style lang="scss">
.confirm {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.confirm {

  @include am.wrapper();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();

    &__text {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }

    &__change-number {
      color: var.$green-dark;
      cursor: pointer;
      text-align: center;
      margin: 8px 0 24px 0;

      &:hover {
        color: var.$green-main;
      }
    }

    &__btn {
      margin-top: 16px;
      box-shadow: none !important;
    }
  }
}
</style>
