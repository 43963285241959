<template>
  <Popup v-model="visible" size="sm" class="popup-yandex">
    <div class="popup-yandex__title font-h1 u-text-center">
      {{ $t("auth.signin.yandex.popup.title") }}
    </div>
    <div class="popup-yandex__text font-semibold">
      {{ $t("auth.signin.yandex.popup.text") }}
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/ui-kit/popup/index.vue";

export default {
  components: {
    Popup,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-yandex {
  &__title {
    margin-bottom: 27px;
  }

  &__text {
    text-align: center;
  }

  &__accept {
    &-button {
      width: 100%;
    }
  }
}
</style>
