<template>
  <Modal class="signin__wrapper" @close="close()">
    <div class="signin">
      <div class="signin__header">
        <div class="signin__header__icon-back" @click="back()">
          <Icon icon="back" color="#9999A9" />
        </div>
        <div
          class="signin__header__label font-h1"
          v-text="$t('auth.signin.title')"
        ></div>
        <div class="signin__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>

      <div class="signin__body">
        <Input
          v-show="isEmailType"
          v-bind="inputEmail"
          v-model="inputEmail.value"
          :error="inputEmail.error"
          @keyup.native.enter="checkCredentials()"
        />

        <Input
          v-show="!isEmailType"
          v-bind="inputPhone"
          v-model="inputPhone.value"
          :error="inputPhone.error"
          :autocomplete="'tel'"
          :phone="true"
          @keyup.native.enter="checkCredentials()"
        />

        <Button
          class="signin__body__btn"
          :text="signinText.btnNext"
          @click="checkCredentials()"
        />

        <span class="divider" v-text="$t('auth.signin.or')"></span>

        <div class="signin__body__social">
          <div
            class="signin__body__social__btn"
            v-for="soc in socialNets"
            v-bind="soc.code === 'apple' && isSafari && appleSoc"
            :key="soc.code"
            :style="{
              backgroundImage: soc.imgUrl,
              backgroundSize: soc.size,
            }"
            @click="clickSocial(soc)"
          ></div>
        </div>

        <Button
          class="signin__body__btn"
          color="white"
          :text="signinText.btnChange"
          @click="changeSigninType()"
        />

        <YtButton
          v-if="isShowYandexId"
          class="signin__body__btn__yandex"
          color="black"
          @click="clickYandexIdBtn()"
        >
          <div></div>
          <div class="font-h2" v-text="$t('auth.signin.yandex.button')"></div>
        </YtButton>
      </div>
    </div>

    <PopupYandex v-model="popupYandex" />
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Input from "@/components/ui-kit/input/index.vue";
import Button from "@/components/ui-kit/button/index.vue";
import YtButton from "@/components/ui-kit/yt-button/index.vue";
import PopupYandex from "./popup-yandex.vue";

import { axiosAuth } from "@/services/scopes/axios-auth.js";
import { validateEmail } from "@/assets/js/utils/validate-email.js";
import { ytEvents } from "@/plugins/analytics";
import { i18n } from "@/plugins/i18n";
import { sizeSm } from "@/assets/js/utils/mq";

export default {
  components: {
    Icon,
    Modal,
    Input,
    Button,
    YtButton,
    PopupYandex,
  },
  props: {
    socialNets: {
      type: Array,
      required: true,
    },
    isSafari: {
      type: Boolean,
      required: true,
    },
    appleSoc: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isRussian: i18n.locale === "ru",
      isEmailType: true,
      inputEmail: {
        value: "",
        placeholder: "E-mail",
        error: false,
        autocomplete: "email",
        name: "email",
      },
      inputPhone: {
        value: "",
        phone: true,
        error: false,
        autocomplete: "tel",
        name: "tel",
      },
      popupYandex: false,
    };
  },
  computed: {
    signinText() {
      return {
        btnNext: this.isEmailType
          ? this.$t("auth.signin.continueWithEmail")
          : this.$t("auth.signin.continueWithPhone"),
        btnChange: this.isEmailType
          ? this.$t("auth.signin.loginWithPhone")
          : this.$t("auth.signin.loginWithEmail"),
      };
    },
    isShowYandexId() {
      return false; //this.sizeSm && this.isRussian;
    },
    sizeSm,
  },
  methods: {
    back() {
      ytEvents.track("Sign-in popup", "Click Back button");
      this.$emit("showInitial");
    },
    close() {
      this.$emit("close");
    },
    async checkCredentials() {
      this.$emit("loading", true);
      if (this.isEmailType) {
        ytEvents.track("Sign-in popup", "Click Email continue button");

        const email = this.inputEmail.value.trim();
        const check = this.checkEmail(email);

        if (check) {
          const res = await axiosAuth.checkAuthEmail(email).catch(() => {
            this.$emit("loading", false);
            this.$emit("showSignup", { email });
          });

          if (res?.data?.success) {
            this.$emit("loading", false);
            this.$emit("showPassword", email);
          }
        }
      } else if (this.inputPhone.value.replace(/ /g,'').length < 12) {
        this.inputPhone.error = this.$t("auth.signin.wrongPhone");
      } else if (this.inputPhone.value) {
        const check = await axiosAuth
          .checkAuthPhone(this.inputPhone.value)
          .catch(() => {
            this.$emit("loading", false);
            this.$emit("showSignup", { phone: this.inputPhone.value });
          });

        if (check?.data?.success) {
          const res = await axiosAuth
            .sendAuthCode(this.inputPhone.value)
            .catch((e) => {
              if (e.response) {
                this.inputPhone.error = e.response.data.data.message;
              }
            });

          if (res?.data?.success) {
            this.$emit("loading", false);
            this.$emit("showConfirmSms", {
              phone: this.inputPhone.value,
              validate: false,
            });
          }
        }
      }
      this.$emit("loading", false);
    },
    checkEmail(email) {
      if (!email || !validateEmail(email)) {
        this.inputEmail.error = this.$t("auth.signin.wrongEmail");
        return false;
      }
      this.inputEmail.error = false;
      return true;
    },
    changeSigninType() {
      if (this.isEmailType) {
        ytEvents.track("Sign-in popup", "Click Phone button");
      }
      this.isEmailType = !this.isEmailType;
    },
    clickSocial(soc) {
      ytEvents.track(
        "Sign-in popup",
        "Click OAuth button",
        soc.code,
        `${soc.code} Sign-in popup`
      );
      this.$emit("openPopup", soc);
    },
    clickYandexIdBtn() {
      this.popupYandex = true;
      ytEvents.track("Sign-in popup", "Click YandexID button");
    },
  },
  mounted() {
    if (this.isSafari) {
      // eslint-disable-next-line no-undef
      AppleID.auth.init(
        JSON.parse(this.socialNets.find(({ code }) => code === "apple").url)
      );
    }
  },
};
</script>

<style lang="scss">
.signin {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.signin {

  @include am.wrapper();
  @include am.divider();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();

    &__btn {
      margin-top: 16px;
      box-shadow: none !important;
      width: 100%;

      &__yandex {
        @extend .signin__body__btn;

        display: flex !important;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;

        & > div {
          &:first-child {
            width: 24px;
            height: 24px;
            background: url("../../../assets/images/yandex.png") center
            no-repeat;
            background-size: contain;
            border-radius: 50%;
          }
        }
      }
    }

    &__social {
      display: flex;
      justify-content: space-between;
      grid-gap: 16px;

      & > div {
        width: 100%;
        height: 56px;

        border: 1px solid var.$gray-dark;
        border-radius: 8px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px auto;

        cursor: pointer;

        &:hover {
          border-color: var.$secondary;
        }
      }
    }


  }
}
</style>
