<template>
  <transition name="fade">
    <div class="auth" v-show="isAuth">
      <Loader v-show="loading" class="auth__loader" />

      <Initial
        v-if="isInitial"
        @close="close()"
        @showSignup="showSignup"
        @showSignin="showSignin"
      />
      <Signin
        v-if="isSignin"
        :socialNets="socialNets"
        :isSafari="isSafari"
        :appleSoc="appleSoc"
        @close="close()"
        @loading="setLoading"
        @showSignup="showSignup"
        @showInitial="showInitial(true)"
        @showPassword="showPassword"
        @showConfirmSms="showConfirmSms"
        @openPopup="openPopup"
        />
      <Existing
        v-if="isExisting"
        :email="email"
        :phone="phone"
        :avatar="avatar"
        :name="name"
        :eixstingSocial="eixstingSocial"
        :socialNets="socialNets"
        :isSafari="isSafari"
        :appleSoc="appleSoc"
        :authType="authType"
        @close="close()"
        @showInitial="showInitial(true)"
        @showPassword="showPassword"
        @showConfirmSms="showConfirmSms"
        @openPopup="openPopup"
        @loading="setLoading"
      />
      <Password
        v-if="isPassword"
        :email="email"
        :show-reset-password="isResetPassword"
        @close="close()"
        @loading="setLoading"
        @showInitial="showInitial"
        @success="onSuccess"
      />
      <ConfirmSms
        v-if="isCofirm"
        :phone="phone"
        :validate="validate"
        @close="close()"
        @loading="setLoading"
        @showInitial="showInitial"
        @success="onSuccess"
      />
      <Signup
        v-if="isSignup"
        :socialNets="socialNets"
        :isSafari="isSafari"
        :appleSoc="appleSoc"
        :email="email"
        :phone="phone"
        @close="close()"
        @loading="setLoading"
        @showInitial="showInitial"
        @success="onSuccess"
        @openPopup="openPopup"
      />
    </div>
  </transition>
</template>

<script>
import Loader from "@/components/ui-kit/loader/index.vue";
import Initial from "./components/initial.vue";
import Existing from "./components/existing.vue";
import Password from "./components/password.vue";
import ConfirmSms from "./components/confirm-sms.vue";
import Signup from "./components/signup.vue";
import Signin from "./components/signin.vue";

import { axiosAuth } from "@/services/scopes/axios-auth.js";
import { ytEvents } from "@/plugins/analytics";
import { mapState, mapMutations } from "vuex";
import { i18n } from "@/plugins/i18n";

export default {
  components: {
    Loader,
    Initial,
    Existing,
    Password,
    ConfirmSms,
    Signup,
    Signin
  },
  data() {
    return {
      isRussian: i18n.locale === "ru",
      loading: false,
      isAuth: false,
      isInitial: false,
      isExisting: false,
      isPassword: false,
      isResetPassword: false,
      isCofirm: false,
      isSignup: false,
      isSignin: false,
      socialNets: [],
      authType: null,
      email: null,
      phone: null,
      avatar: null,
      name: null,
      request: null,
      eixstingSocial: null,
      validate: false,
      isSafari: false,
      appleSoc: {
        id: "appleid-signin",
        "data-type": "sign in",
      },
      popup: null,
    };
  },
  computed: {
    ...mapState({
      getUserLoginStatus: (state) => state.user.getUserLoginStatus,
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "user/setUser",
    }),
    async open(request) {
      if (request) {
        this.request = request;
      }

      this.isAuth = true;

      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      const body = document.body;
      body.style.position = "fixed";
      body.style.width = "100%";
      body.style.top = `-${scrollY}`;

      const loginLastSocial = document.cookie
        .split(";")
        .find((item) => item.includes("loginLastSocService"))
        ?.split("=");
      const loginLastUser = document.cookie
        .split(";")
        .find((item) => item.includes("loginLastUser"))
        ?.split("=");
      if (loginLastUser) {
        if (loginLastSocial) {
          this.eixstingSocial = loginLastSocial[1];
        }
        const decodedCookie = decodeURIComponent(loginLastUser[1]).split("|");
        this.authType = decodedCookie[0];
        if (this.authType === "email" || this.authType === "soc") {
          this.email = decodedCookie[1];
        } else {
          this.phone = `+${decodedCookie[1].replace("+", "")}`;
        }
        this.avatar = decodedCookie[2];
        this.name = decodedCookie[3];
        this.isExisting = true;
        this.isInitial = false;

        ytEvents.track(
          "Sign-in popup",
          "Show Known popup",
          loginLastSocial ? this.eixstingSocial : this.email
        );
      } else {
        ytEvents.track("Sign-in popup", "Show Unknown popup");
        this.isInitial = true;
        this.isExisting = false;
      }
      await this.fetchOAuthServices();

      if (request) {
        this.request = request;
      }
    },
    async onSuccess() {
      const event = this.request?.["emit-event"];
      fakeWindow.dispatchEvent(
        new CustomEvent("auth.done", {
          detail: event,
        })
      );
      if (event) {
        this.close();
        fakeWindow.dispatchEvent(
          new CustomEvent("auth.success", {
            detail: event,
          })
        );
        return;
      }

      if (this.request) {
        let ytObj = youtravel || {};
        await ytObj.ModalController.openModal({
          ...this.request,
          isLegacy: true,
        });

        if (this.request?.bodyRequest?.action === "AddToFavorite") {
          fakeWindow.location.reload();
        }
      }
      this.close();
    },
    onSocialCallback(data) {
      if (data.success) {
        this.onSuccess();
      }
    },
    async fetchOAuthServices() {
      // get available social networks
      if (!this.socialNets.length) {
        const {
          data: { success: successSocialNets, data: socialNets },
        } = await axiosAuth.getServices("#").catch((e) => console.log(e));
        if (successSocialNets) {
          this.socialNets = socialNets
            .map((sn) => {
              let imgUrl;

              switch (sn.code) {
                case "apple":
                {
                  if (this.isSafari) {
                    let script = document.createElement("script");
                    script.src =
                      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
                    document.head.append(script);
                    script.onload = function () {
                      document.addEventListener(
                        "AppleIDSignInOnSuccess",
                        (data) => {
                          // eslint-disable-next-line no-undef
                          $.ajax({
                            url: "/bitrix/tools/oauth/apple.php",
                            method: "POST",
                            data: data.detail.authorization,
                            success: (data) => {
                              const { detail } = JSON.parse(
                                data.replace(/'/g, '"')
                              );

                              const authEvent = new CustomEvent("Auth::data", {
                                detail,
                              });

                              fakeWindow.dispatchEvent(authEvent);
                            },
                          });
                        }
                      );
                      this.loading = false;
                    };
                  }
                  imgUrl =
                    "url(/local/templates/youtravel/static/i/social/apple-fill.png)";
                }
                  break;
                case "Facebook":
                  imgUrl =
                    "url(/local/templates/youtravel/static/i/social/facebook-fill.png)";
                  break;
                case "GoogleOAuth":
                  imgUrl =
                    "url(/local/templates/youtravel/static/i/social/google-fill.png)";
                  break;
                case "VKontakte":
                  imgUrl =
                    "url(/local/templates/youtravel/static/i/social/vk-fill.png)";
                  break;
              }

              return {
                ...sn,
                imgUrl,
              };
            })
            .filter(({ code }) => (this.isRussian ? code !== "Facebook" : true))
            .filter(({ code }) =>
              !this.isRussian ? code !== "VKontakte" : true
            );
        }
      }
    },
    openPopup({ url, code }) {
      if (code === "apple" && this.isSafari) return;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=600,height=425`;
      if (this.popup) {
        this.popup.close();
        this.popup = null;
      }
      this.popup = fakeWindow.open(url, "auth", params);
    },
    close() {
      ytEvents.track("Sign-in popup", "Close Popup");

      const event = this.request?.["emit-event"];

      this.isAuth = false;
      this.loading = false;
      this.isInitial = false;
      this.isExisting = false;
      this.isPassword = false;
      this.isCofirm = false;
      this.isSignup = false;
      this.isSignin = false;
      this.email = null;
      this.phone = null;
      this.avatar = null;
      this.name = null;
      this.request = null;
      this.validate = false;

      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      fakeWindow.scrollTo(0, parseInt(scrollY || "0") * -1);
      this.getUserLoginStatus &&
        fakeWindow.dispatchEvent(new CustomEvent("auth.close", { detail: event }));
    },
    setLoading(bool) {
      this.loading = bool;
    },
    showInitial(bool) {
      ytEvents.track("Sign-in popup", "Show Unknown popup");

      if (bool) {
        this.email = "";
      }
      this.isInitial = true;
      this.isExisting = false;
      this.isPassword = false;
      this.isCofirm = false;
      this.isSignup = false;
      this.isSignin = false;

      this.validate = false;
    },
    showSignup({ email = null, phone = null }) {
      ytEvents.track("Sign-in popup", "Show Sign-up popup");
      this.email = email;
      this.phone = phone;

      this.isSignup = true;
      this.isSignin = false;
      this.isInitial = false;
      this.isExisting = false;
      this.isPassword = false;
      this.isCofirm = false;
    },
    showSignin() {
      ytEvents.track("Sign-in popup", "Show Sign-in popup");
      this.isSignin = true;
      this.isSignup = false;
      this.isInitial = false;
      this.isExisting = false;
      this.isPassword = false;
      this.isCofirm = false;
    },
    showPassword(email, resetPassword = false) {
      ytEvents.track("Sign-in popup", "Show Password request popup");

      this.email = email;

      this.isSignin = false;
      this.isSignup = false;
      this.isInitial = false;
      this.isExisting = false;
      this.isPassword = true;
      this.isCofirm = false;
      this.isResetPassword = resetPassword;
    },
    showConfirmSms({ phone, validate }) {
      ytEvents.track("Sign-in popup", "Show Enter sms popup");

      this.phone = phone;
      this.validate = validate;

      this.isSignin = false;
      this.isSignup = false;
      this.isInitial = false;
      this.isExisting = false;
      this.isPassword = false;
      this.isCofirm = true;
    },
    showResetPasswordModal() {
      this.isAuth = true;

      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      const body = document.body;
      body.style.position = "fixed";
      body.style.width = "100%";
      body.style.top = `-${scrollY}`;
      this.showPassword('',true);
    }
  },
  async mounted() {
    this.loading = true;
    if(vueOptions.ssr) {
      this.isSafari = false;
    } else {
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }


    if (!fakeWindow.showAuthModal) {
      fakeWindow.showVueAuthModal = (request = false) => {
        this.open(request);
      };
    }

    if (!fakeWindow.showVueResetPasswordModal) {
      fakeWindow.showVueResetPasswordModal = () => {
        this.showResetPasswordModal();
      };
    }

    fakeWindow.processSocialData = (data) => {
      this.onSocialCallback(data);
    };

    fakeWindow.addEventListener("Auth::data", (e) => {
      if (e.detail.user.isLoggedIn) {
        this.setUser(e.detail.user);
        this.onSuccess();
      } else {
        // TODO: social auth failed
      }
    });

    fakeWindow.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${fakeWindow.scrollY}px`
      );
    });

    this.loading = false;
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("scroll");
    fakeWindow.removeEventListener("Auth::data");
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/global.scss";
@use "@/assets/scss/font-sf-pro.scss";

.auth {
  position: absolute;
  z-index: 10000000;

  & * {
    font-family: "SF Pro Text";
    font-style: normal;
    box-sizing: border-box;
  }

  &__loader {
    position: fixed;
    z-index: 1000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #appleid-signin > div {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: var.$size-md) {
  * >>> .custom-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    align-items: baseline !important;
    overflow: auto !important;

    &__blur {
      padding: 0 !important;
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }
  }
}
</style>
