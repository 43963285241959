<template>
  <Modal class="initial__wrapper" @close="close()">
    <div class="initial">
      <div class="initial__header">
        <div
          class="initial__header__label font-h1"
          v-text="$t('auth.initial.title')"
        ></div>
        <div class="initial__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>
      <div class="initial__body">
        <Button
          class="initial__body__btn"
          :text="$t('auth.initial.signup')"
          @click="$emit('showSignup',{email: null, phone: null})"
        />

        <span class="divider" v-text="$t('auth.initial.or')"></span>

        <Button
          class="initial__body__btn"
          color="white"
          :text="$t('auth.initial.login')"
          @click="$emit('showSignin')"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Button from "@/components/ui-kit/button/index.vue";
import { sizeSm } from "@/assets/js/utils/mq";

export default {
  components: {
    Icon,
    Modal,
    Button,
  },
  computed: {
    sizeSm,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.initial {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.initial {

  @include am.wrapper();
  @include am.divider();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();
  }

}
</style>
