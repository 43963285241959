import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosAuth = {
  getUser() {
    return axiosYtApiV2.get("/user");
  },

  // social networks
  getServices(backurl) {
    return axiosYtApiV2.get(`/auth/getServices?backurl=${backurl}`);
  },

  // email
  signinByEmail(credentials) {
    return axiosYtApiV2.post("/auth/post", credentials);
  },
  registerUser(user) {
    return axiosYtApiV2.post("/auth/registration", user);
  },
  recoverPassword(email, redirect='') {
    return axiosYtApiV2.post("/auth/recover-password", { email, redirect });
  },
  checkAuthEmail(email) {
    return axiosYtApiV2.post("/auth/checkAuthEmail", { email });
  },

  // phone
  signinByPhone(credentials) {
    return axiosYtApiV2.post("/auth/phone", credentials);
  },
  sendAuthCode(phone) {
    return axiosYtApiV2.post("/auth/sendAuthCode", { phone });
  },
  checkAuthPhone(phone) {
    return axiosYtApiV2.post("/auth/checkAuthPhone", { phone });
  },
  sendValidationCode(phone) {
    return axiosYtApiV2.post("/auth/validatePhoneSend", { phone });
  },
  validateCode(credentials) {
    return axiosYtApiV2.post("/auth/validatePhoneCheck", credentials);
  },
};

export { axiosAuth };
