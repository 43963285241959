<template>
  <Modal class="signup__wrapper" @close="close()">
    <div class="signup">
      <div class="signup__header">
        <div class="signup__header__icon-back" @click="back()">
          <Icon icon="back" color="#9999A9" />
        </div>

        <div
          class="signup__header__label font-h1"
          v-text="$t('auth.signup.title')"
        ></div>

        <div class="signup__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>

      <div class="signup__body" >
        <div class="signup__body__switcher-wrapper">
          <div class="signup__body__switcher">
            <div
              class="signup__body__switcher__item"
              :class="{
                'signup__body__switcher__item--active':
                  user.userRole === 'User',
              }"
              v-html="$t('auth.signup.traveler')"
              @click="setUserRole('User')"
            ></div>

            <div
              class="signup__body__switcher__item"
              :class="{
                'signup__body__switcher__item--active':
                  user.userRole === 'Expert',
              }"
              v-html="$t('auth.signup.expert')"
              @click="setUserRole('Expert')"
            ></div>
          </div>
        </div>

        <div
          v-if="isRussian"
          class="signup__body__social-vk"
          @click="clickSocial(vkSocialNet)"
        >
          <Icon icon="VK" />
          {{ $t('auth.signup.vk.button') }}
        </div>

        <div v-else class="signup__body__social">
          <div
            class="signup__body__social__btn"
            v-for="soc in socialNets"
            v-bind="soc.code === 'apple' && isSafari && appleSoc"
            :key="soc.code"
            :style="{
              backgroundImage: soc.imgUrl,
              backgroundSize: soc.size,
            }"
            @click="clickSocial(soc)"
          ></div>
        </div>

        <span class="divider" v-text="$t('auth.signin.or')"></span>

        <div class="signup__body__input__wrapper">
          <Input
            :autocomplete="'given-name'"
            v-model="user.firstName"
            :placeholder="$t('auth.signup.name')"
            :error="errors.firstName"
            @keyup.native.enter="signup()"
          />

          <Input
            :autocomplete="'family-name'"
            v-model="user.lastName"
            :placeholder="$t('auth.signup.lastName')"
            :error="errors.lastName"
            @keyup.native.enter="signup()"
          />
        </div>

        <div v-if="useForTest('C')"
             class="signup__body__input">
          <div class="signup__body__input__wrapper">
            <ButtonSelect
              v-for="(button, idx) in getButtonsFormownership()"
              :text="button.label"
              :active="user.formownership === button.code"
              :key="idx"
              @click="setButtonSelect('formownership', button.code)"
            />
          </div>
          <div v-if="errors.formownership"
               class="input-error">
            <span>{{ errors.formownership }}</span>
          </div>
        </div>

        <div class="signup__body__input" v-if="useForTest('C') && user.formownership">
            <ButtonSelect
              v-for="(button, idx) in getButtonsType"
              :text="button.label"
              :active="user.type === button.code"
              :key="idx"
              @click="setButtonSelect('type', button.code)"
            />
          <div v-if="errors.type"
               class="input-error">
            <span>{{ errors.type }}</span>
          </div>
        </div>

        <Input
          class="signup__body__input"
          :autocomplete="'email'"
          v-model="user.email"
          placeholder="E-mail"
          :error="errors.email"
          @keyup.native.enter="signup()"
        />

        <Input
          ref="phone"
          :autocomplete="'tel'"
          class="signup__body__input"
          v-model="user.phone"
          :phone="true"
          :error="errors.phone"
          @keyup.native.enter="signup()"
        />

        <Input
          class="signup__body__input"
          :autocomplete="'new-password'"
          v-model="user.password"
          :placeholder="$t('auth.password.password')"
          name="password"
          :inputType="pwdInputType"
          :eye="true"
          :error="errors.password"
          @inputType="setInputType()"
          @keyup.native.enter="signup()"
        />

        <Button
          class="signup__body__btn"
          :text="$t('auth.signup.signup')"
          @click="signup()"
        />

        <div
          class="signup__body__legal font-text-small"
          v-html="$t('auth.signup.terms')"
        ></div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Input from "@/components/ui-kit/input/index.vue";
import ButtonSelect from "@/components/ui-kit/button-select/index.vue";
import Button from "@/components/ui-kit/button/index.vue";

import { axiosAuth } from "@/services/scopes/axios-auth.js";
import { validateEmail } from "@/assets/js/utils/validate-email.js";
import { ytEvents } from "@/plugins/analytics";
import { i18n } from "@/plugins/i18n";

export default {
  components: {
    Icon,
    Modal,
    Input,
    Button,
    ButtonSelect,
  },
  props: {
    socialNets: {
      type: Array,
      required: true,
    },
    isSafari: {
      type: Boolean,
      required: true,
    },
    appleSoc: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isRussian: i18n.locale === "ru",
      pwdInputType: "password",
      user: {
        userRole: "User",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        abtest: "A",
        formownership: "",
        type: "",
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        password: false,
        formownership: false,
        type: false,
      },
    };
  },
  methods: {
    back() {
      ytEvents.track("Sign-in popup", "Click Back button");
      this.$emit("showInitial");
    },
    close() {
      this.$emit("close");
    },
    setInputType() {
      this.pwdInputType =
        this.pwdInputType === "password" ? "text" : "password";
    },
    checkCredentials() {
      let valid = true;
      Object.keys(this.user).forEach((key) => {
        if (!this.user[key]) {
          if (!this.useForTest('C') && (key === 'formownership' || key === 'type')) {
            this.errors[key] = false;
          } else {
            this.errors[key] = this.$t("auth.signup.fill");
            valid = false;
          }
        } else if (key === 'phone' && this.user[key]?.replace(/ /g,'').length < 12) {
          this.errors[key] = this.$t("auth.signin.wrongPhone");
          valid = false;
        } else {
          this.errors[key] = false;
        }
      });

      if (this.user.email && !validateEmail(this.user.email)) {
        this.errors.email = this.$t("auth.signin.wrongEmail");
        valid = false;
      }

      return valid;
    },
    async signup() {
      this.$emit("loading", true);

      if (this.checkCredentials()) {
        const resRegister = await axiosAuth
          .registerUser({
            ...this.user,
            email: this.user.email.trim(),
            confirmPassword: this.user.password,
          })
          .catch((e) => {
            if (e.response) {
              let field = e.response.data.data.errors?.field;
              if (field !== undefined && field !== '') {
                this.errors[field] = e.response.data.data.errors?.message;
              } else {
                this.errors.password = e.response.data.data.message;
              }
            }
          });

        if (resRegister?.data?.success) {
          const res = await axiosAuth
            .sendValidationCode(this.user.phone)
            .catch((e) => {
              if (e.response) {
                this.errors.password = e.response.data.data.message;
              }
            });

          if (res?.data?.success) {
            this.$emit("showConfirmSms", {
              phone: this.user.phone,
              validate: true,
            });
          }
          this.$emit("success");

          let abtest = this.user.abtest;
          if (abtest === "C") {
            ytEvents.track("Sign-in popup", "Click Register User " + abtest);
          } else {
            ytEvents.track("Sign-in popup", "Click Sign-up button");
          }

          const user = JSON.parse(
            resRegister.data.data.user.replace(/'/g, '"')
          );
          const authUserEvent = new CustomEvent("Auth::user", {
            detail: { ...user },
          });
          fakeWindow.dispatchEvent(authUserEvent);
        }
      }

      this.$emit("loading", false);
    },
    clickSocial(soc) {
      ytEvents.track(
        "Sign-in popup",
        "Click OAuth button",
        `${soc.code} Sign-up popup`
      );

      this.$emit("openPopup", soc);
    },
    setUserRole(user) {
      if(user === 'Expert' && this.isRussian) {
        const newLkFrontHost = process.env.VUE_APP_NEWLK_FRONT_HOST.replace(/\/$/, '');
        window.open(newLkFrontHost + '/auth/register', '_blank');
      } else {
        ytEvents.track("Sign-in popup", "Click Switch role", user);
        this.user.userRole = user;
      }
    },
    setButtonSelect(code, value) {

      // Обнуляем подпункт
      if (code == 'formownership') {
        this.user.type = '';
      }

      this.user[code] = value;
    },
    getButtonsFormownership() {
      return [
        {
          code: "individual",
          label: this.$t('auth.signup.individual'),
          },
        {
          code: "entity",
          label: this.$t('auth.signup.entity'),
          },
      ];
    },
    useForTest(version = 'A') {
      if (this.getABTest() === version) {
        return this.user.userRole === 'Expert';
      }

      return false;
    },
    getABTest() {
      let abtest = document.getElementById('signup__abtest').getAttribute('data-abvalue');
      this.user.abtest = abtest

      return abtest;
    },
  },
  computed: {
    vkSocialNet() {
      return this.socialNets.filter(({code}) => code === "VKontakte")[0];
    },
    getButtonsType() {
      let buttons = [
        {
          code: "team",
          label: this.$t('auth.signup.team'),
        },
        {
          code: "tourLeader",
          label: this.$t('auth.signup.tourLeader'),
        },
      ];

      if (this.user.formownership === 'entity') {
        buttons.push({
            code: "touroperator",
            label: this.$t('auth.signup.touroperator'),
          });
      }

      return buttons;
    },
  },
  mounted() {
    if (this.isSafari) {
      // eslint-disable-next-line no-undef
      AppleID.auth.init(
        JSON.parse(this.socialNets.find(({ code }) => code === "apple").url)
      );
    }
    if (this.email) {
      this.user.email = this.email;
    }
    if (this.phone) {
      this.user.phone = this.phone;
    }
  },
};
</script>

<style lang="scss">
.signup {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.signup {

  @include am.wrapper();
  @include am.divider();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();

    &__switcher {
      display: flex;
      grid-gap: 4px;
      background: var.$gray-light;
      padding: 4px;
      border-radius: 12px;
      display: flex;
      width: 100%;

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 9.75px;
        height: 40px;
        width: 100%;
        border-radius: 8px;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: var.$black;
        cursor: pointer;

        &--active {
          background: var.$white;
        }

        &:hover:not(.signup__body__switcher__item--active) {
          background: var.$gray-pale;
        }
      }
    }

    &__social {
      display: flex;
      justify-content: space-between;
      grid-gap: 16px;

      & > div {
        width: 100%;
        height: 56px;

        border: 1px solid var.$gray-dark;
        border-radius: 8px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px auto;

        cursor: pointer;

        &:hover {
          border-color: var.$secondary;
        }
      }
    }

    &__social-vk {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      grid-gap: 16px;
      color: var.$black;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: -0.24px;
      width: 100%;
      height: 56px;

      border: 1px solid var.$gray-dark;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        border-color: var.$secondary;
      }
    }

    &__input {
      &__wrapper {
        display: flex;

        & > div {
          width: 100%;

          &:first-child >>> .input-wrapper {
            border-radius: 8px 0 0 8px;
            border-right-width: 0.5px;
          }

          &:last-child >>> .input-wrapper {
            border-radius: 0 8px 8px 0;
            border-left-width: 0.5px;

            &:focus-within:not(.input-wrapper--disabled):not(.input-wrapper--invalid) {
              margin-left: -0.5px;
            }
          }
        }
      }

      margin-top: 24px;
    }

    &__legal {
      color: var.$secondary;
      font-weight: 400;
      text-align: center;

      & >>> a {
        color: var.$secondary;
        text-decoration-line: underline;

        &:visited {
          color: var.$secondary;
        }
        &:hover {
          color: var.$secondary;
          text-decoration-line: unset;
        }
        &:active {
          color: var.$secondary;
        }
      }
    }

    &__btn {
      margin: 20px 0;
      box-shadow: none !important;
    }

    .input-error {
      color: #f84565;
      line-height: 18px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;
      margin-bottom: 0;
      padding: 0;
    }
  }
}
</style>
