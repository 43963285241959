<template>
  <Modal class="existing__wrapper" @close="close()">
    <div class="existing">
      <div class="existing__header">
        <div
          class="existing__header__label font-h1"
          v-text="$t('auth.existing.title', { name })"
        ></div>
        <div class="existing__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>

      <div class="existing__body">
        <div class="existing__body__photo">
          <img v-if="photoUrl" :src="photoUrl" height="65" width="64" />
          <Icon v-if="!photoUrl" icon="user" color="#D1D7E8" />
        </div>

        <div class="existing__body__email">
          <Icon icon="email" color="#121212" />

          <div class="font-text" v-text="hiddenCredentials"></div>
        </div>

        <Button
          v-if="!socialNetwork"
          class="existing__body__btn"
          :text="$t('auth.existing.login')"
          @click="clickSigninPwd()"
        />

        <Button
          v-if="socialNetwork"
          class="existing__body__btn"
          :color="'white'"
          :text="
            $t('auth.existing.continueWith', { social: socialNetwork.name })
          "
          @click="clickSigninSoc(socialNetwork)"
        >
          <div
            class="existing__body__btn__icon"
            :style="{
              backgroundImage: socialNetwork.imgUrl,
            }"
          >
            <div
              v-if="socialNetwork.code === 'apple' && isSafari"
              v-bind="appleSoc"
            ></div>
          </div>
        </Button>

        <div class="existing__body__btn-tip__wrapper">
          <span
            class="existing__body__btn-tip font-h2"
            v-text="$t('auth.existing.different')"
            @click="clickOtherType()"
          ></span>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Button from "@/components/ui-kit/button/index.vue";
import { ytEvents } from "@/plugins/analytics";
import { axiosAuth } from "@/services/scopes/axios-auth.js";

export default {
  components: {
    Icon,
    Modal,
    Button,
  },
  props: {
    authType: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    eixstingSocial: {
      type: String,
      default: "",
    },
    socialNets: {
      type: Array,
      required: true,
    },
    isSafari: {
      type: Boolean,
      required: true,
    },
    appleSoc: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      socalNet: "",
    };
  },
  computed: {
    hiddenCredentials() {
      return this.authType === "phone"
        ? `${this.phone.slice(0, 2)}•••••${this.phone.slice(
            this.phone.length - 4
          )}`
        : this?.email?.replace(/(\w{1})[\w.-]+@([\w.]+\w)/, "$1•••••@$2");
    },
    photoUrl() {
      return (
        this.avatar && `${process.env.VUE_APP_BASE_URL || ""}${this.avatar}`
      );
    },
    socialNetwork() {
      return (
        this.eixstingSocial &&
        this.socialNets.find(({ code }) => code === this.eixstingSocial)
      );
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clickSigninSoc(soc) {
      ytEvents.track("Sign-in popup", "Click Sign-in known button", soc.code);

      this.$emit("openPopup", soc);
    },
    async clickSigninPwd() {
      ytEvents.track("Sign-in popup", "Click Sign-in known button", this.email);

      if (this.authType === "phone") {
        this.$emit("loading", true);
        const res = await axiosAuth.sendAuthCode(this.phone).catch((e) => {
          console.log(e);
          if (e.response) {
            console.log(e.response);
          }
        });

        if (res?.data?.success) {
          this.$emit("showConfirmSms", { phone: this.phone, validate: false });
        }

        this.$emit("loading", false);
      } else {
        this.$emit("showPassword", this.email);
      }
    },
    clickOtherType() {
      ytEvents.track("Sign-in popup", "Click Sign-in with other type");

      this.$emit("showInitial");
    },
  },
  mounted() {
    if (this.isSafari) {
      // eslint-disable-next-line no-undef
      AppleID.auth.init(
        JSON.parse(this.socialNets.find(({ code }) => code === "apple").url)
      );
    }
  },
};
</script>

<style lang="scss">
.existing {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.existing {

  @include am.wrapper();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();

    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 64px !important;
      height: 64px;
      border-radius: 50%;
      background: var.$gray-light;

      & > img {
        border-radius: 50%;
        object-fit: fill;
      }
    }

    &__email {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 8px;
      margin: 12px 0 24px 0;

      & > div {
        font-weight: 400;
      }
    }

    &__btn {
      position: relative;
      box-shadow: none !important;

      &__icon {
        background-size: 20px;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center;

        & > div {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &-tip {
        @include am.btn-tip();
      }
    }
  }
}
</style>
