<template>
  <Modal class="password__wrapper" @close="close()">
    <div class="password">
      <div class="password__header">
        <div class="password__header__icon-back" @click="back()">
          <Icon icon="back" color="#9999A9" />
        </div>

        <div
          class="password__header__label font-h1"
          v-text="popupTitle"
        ></div>

        <div class="password__header__icon-close" @click="close()">
          <Icon icon="close" color="#9999A9" />
        </div>
      </div>

      <div class="password__body">
        <template v-if="!isResetPassword">
          <Input
            v-show="false"
            v-bind="inputEmail"
            v-model="inputEmail.value"
            :error="inputEmail.error"
          />
          <Input
            v-bind="inputPassword"
            v-model="inputPassword.value"
            @inputType="setInputType()"
            @keyup.native.enter="signin()"
          />

          <Button
            class="password__body__btn"
            :text="$t('auth.existing.login')"
            @click="signin()"
          />

          <div class="password__body__btn-tip__wrapper">
            <span
              class="password__body__btn-tip font-h2"
              v-text="$t('auth.password.forgotPassword')"
              @click="clickForgotPwd()"
            ></span>
          </div>
        </template>

        <template v-if="isResetPassword">
          <div
            class="password__body__text"
            :class="{'password__body__text-input-email': !isEmailSent}"
            v-html="resetPasswordText.header"
          ></div>

          <div v-show="isEmailSent" class="password__body__btn-change-email" @click="back()">
            {{$t('auth.password.changeEmail')}}
          </div>

          <Input
            v-show="!isEmailSent"
            v-bind="resetPassword"
            v-model="resetPassword.value"
            @keyup.native.enter="clickResetPassword()"
          />

          <Button
            class="password__body__btn"
            :color="this.isEmailSent ? 'gray' : 'green'"
            :disabled="showTimer"
            :text="resetPasswordText.button"
            @click="clickResetPassword()"
          />
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import Modal from "@/components/ui-kit/modal/index.vue";
import Input from "@/components/ui-kit/input/index.vue";
import Button from "@/components/ui-kit/button/index.vue";

import { axiosAuth } from "@/services/scopes/axios-auth.js";
import { validateEmail } from "@/assets/js/utils/validate-email.js";
import { ytEvents } from "@/plugins/analytics";

export default {
  components: {
    Icon,
    Modal,
    Input,
    Button,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
    showResetPassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timerId: null,
      timeout: 45,
      sec: 0,
      inputEmail: {
        value: this.email,
        placeholder: "E-mail",
        error: false,
        autocomplete: "email",
        name: "email",
      },
      inputPassword: {
        value: "",
        placeholder: this.$t("auth.password.password"),
        inputType: "password",
        name: "password",
        eye: true,
        error: false,
        autocomplete: "current-password",
      },
      resetPassword: {
        value: "",
        placeholder: "E-mail",
        error: false,
      },
      isResetPassword: this.showResetPassword,
      isEmailSent: false,
    };
  },
  watch: {
    email(val) {
      if (val) {
        this.resetPassword.value = val;
      }
    },
  },
  computed: {
    showTimer() {
      return this.isEmailSent && this.timerId
    },
    popupTitle() {
      return this.isResetPassword ? this.$t("auth.password.title") : this.$t('auth.existing.login');
    },
    resetPasswordText() {
      let btnWithTimer = this.showTimer
        ? this.$t("auth.password.resentEmailAfter", {sec: this.sec})
        : this.$t("auth.password.resentEmail");
      return {
        header: this.isEmailSent
          ? this.$t("auth.password.resetPasswordSent", {
              email: this.resetPassword.value,
            })
          : this.$t("auth.password.resetPassword"),
        button: this.isEmailSent
          ? btnWithTimer
          : this.$t("auth.sendLink"),
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    back() {
      ytEvents.track("Sign-in popup", "Click Back button");

      if (this.isResetPassword) {
        if (this.isEmailSent) {
          this.isEmailSent = false;
        } else {
          this.isResetPassword = false;
        }
      } else {
        this.$emit("showInitial");
      }
    },
    setInputType() {
      this.inputPassword.inputType =
        this.inputPassword.inputType === "password" ? "text" : "password";
    },
    checkEmail(email) {
      if (!email || !validateEmail(email)) {
        this.resetPassword.error = this.$t("auth.signin.wrongEmail");
        return false;
      }
      this.resetPassword.error = false;
      return true;
    },
    timer(){
      if(!this.timerId){
        return;
      }
      if(this.sec > 1) {
        this.sec--;
      }
      else {
        clearInterval(this.timerId);
        this.timerId = 0;
      }
    },
    setTimer() {
      clearInterval(this.timerId);
      this.timerId =  setInterval(() => this.timer(), 1000);
    },
    clickForgotPwd() {
      ytEvents.track("Sign-in popup", "Click Forget password");
      ytEvents.track("Sign-in popup", "Show Forget password popup");
      this.isResetPassword = true;
    },
    async clickResetPassword() {
      ytEvents.track("Sign-in popup", "Click Send reset link");

      this.$emit("loading", true);

      if (this.checkEmail(this.resetPassword.value)) {
        const res = await axiosAuth
          .recoverPassword(this.resetPassword.value, fakeWindow.location.pathname)
          .catch((e) => {
            console.log(e);
            if (e.response) {
              this.resetPassword.error = e.response.data.data.message;
            }
          });

        if (res?.data?.success) {
          this.isEmailSent = true;
          this.sec = this.timeout;
          this.setTimer();
        }
      }

      this.$emit("loading", false);
    },
    async signin() {
      ytEvents.track("Sign-in popup", "Click Sign-in password button");

      this.$emit("loading", true);

      this.inputPassword.error = false;

      if (this.inputPassword.value) {
        const res = await axiosAuth
          .signinByEmail({
            login: this.email,
            password: this.inputPassword.value,
          })
          .catch((e) => {
            console.log(e);
            if (e.response) {
              this.inputPassword.error = e.response.data.data.message;
            }
          });

        if (res?.data?.success) {
          this.$emit("success");

          const user = JSON.parse(res.data.data.user.replace(/'/g, '"'));
          const authUserEvent = new CustomEvent("Auth::user", {
            detail: { ...user },
          });
          fakeWindow.dispatchEvent(authUserEvent);
        }
      } else {
        this.inputPassword.error = this.$t("auth.password.enterPassword");
      }

      this.$emit("loading", false);
    },
  },
};
</script>

<style lang="scss">
.password {
  &__wrapper .custom-modal {
    max-width: 440px !important;
  }
}
</style>

<style lang="scss" scoped>
.password {

  @include am.wrapper();

  &__header {
    @include am.header();
  }

  &__body {
    @include am.body();

    &__text {
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      margin-bottom: 8px;
      color: #1A1C20;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.224px;

      &-input-email {
        margin-bottom: 32px;
      }

      & >>> b{
        word-break: break-all;
      }
    }

    &__btn {
      margin-top: 16px;
      box-shadow: none !important;

      &-tip {
        @include am.btn-tip();
      }

      &-change-email{
        width:100%;
        text-align: center;
        color: var.$green-dark;
        font-family: var.$font-family-sf-pro-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px; /* 118.75% */
        letter-spacing: -0.32px;
        margin-bottom: 24px;
        cursor: pointer;
        transition: var.$transition-fast;
        &:hover{
          color: var.$green-hover;
        }
      }
    }
  }
}

</style>
